import React from 'react'
import { Helmet } from 'react-helmet'

import Layout from '../../layout/layout'

import Retailer from '../../svg/street-with-retailer.svg'
import GooglePlay from '../../svg/google-play-badge.svg'
import AppStore from '../../svg/app-store-badge.svg'

const Page = () => (

    <Layout>

        <Helmet>
            <title>ZuprCharger - Download voor iOS of Android</title>
            <meta name="description" content="Met Zupr laat u consumenten online zien waar producten in lokale, fysieke winkels verkrijgbaar zijn." />
        </Helmet>

        <section className="city retailer">
            <h1>Direct aanmelden? Download onze app</h1>
            <p>Download voor Android en iOS</p>
            <div className="badges">
                <a className="badge" target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.zupr.charger">
                    <GooglePlay />
                </a>
                <a className="badge" target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/nl/app/zuprcharger/id1498186416">
                    <AppStore />
                </a>
            </div>
            <div className="background">
                <Retailer />
            </div>
        </section>

        <section className="contact-boost">
            <div className="inner">
                <h2>Heeft u vragen?</h2>
                <p>Voor vragen of toelichting kunt u contact opnemen met het Zupr team.</p>
                <p>
                    <a href="mailto:support@zupr.nl?subject=Interesse in Zupr" className="email">support@zupr.nl</a>
                </p>
            </div>
        </section>

    </Layout>
)

export default Page
